import { onBeforeMount, Ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { handleLoadError } from '@/core/common';
import { LawFirm, useLawFirm } from '@/modules/law_firm/LawFirm';
import { useLawFirmUser } from '@/modules/law_firm/LawFirmUser';

export const useLawFirmRouteLoader = (resource: Ref, lawFirm: Ref<LawFirm>) => {
	const route = useRoute();

	const load = async () => {
		if (!route.params.law_firm_id) {
			return;
		}

		try {
			lawFirm.value = await useLawFirm().get(route.params.law_firm_id as string);
			resource.value = useLawFirmUser(lawFirm.value?.id);
		} catch (e) {
			handleLoadError(e as Error, 'law_firm.index');
		}
	};

	watch(route, load);
	onBeforeMount(load);
};
