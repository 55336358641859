import { ApiParams } from '@/core/http/ApiResource';
import { User, UserResource } from '@/modules/user/User';
import PaginatedResponse from '@/core/http/PaginatedResponse';

class LawFirmUserResource extends UserResource {
	private readonly lawFirmId: number | string;

	constructor(lawFirmId: number | string) {
		super('/api/user');

		this.lawFirmId = lawFirmId;
	}

	async getAll(params: ApiParams = {}, withLoader: boolean = this.withLoader): Promise<PaginatedResponse<User>> {
		return this.getAllRequest(`/api/law_firm/${this.lawFirmId}/user`, params, withLoader);
	}

	post(data: Partial<User>, withLoader: boolean = this.withLoader): Promise<User> {
		return this.getApi(withLoader)
			.post<User>(`/api/law_firm/${this.lawFirmId}/user`, data)
		;
	}
}

export const useLawFirmUser = (lawFirmId: number | string) => new LawFirmUserResource(lawFirmId);
